import styled from 'styled-components';

export const Wrapper = styled.div`
  padding: 12px;
  background-color: ${p => p.theme.color.white100};
  border-radius: 12px;

  /* react-modal Transitions */
  .ReactModal__Content & {
    opacity: 1;
    transition: all 150ms ease-in-out;
  }

  .ReactModal__Content--after-open & {
    /* DEFAULT STYLES */
    opacity: 1;
    width: 33vw;
    max-height: 57vh;

    /* PHONE STYLES */
    @media only screen and (max-width: 767px) {
      @media (orientation: portrait) {
        width: 69vw;
        max-height: 59vh;
      }
      @media (orientation: landscape) {
        width: 90vh;
        max-height: 90vh;
      }
    }

    /* TABLET STYLES */
    @media only screen and (min-width: 768px) and (max-width: 1369px) {
      @media (orientation: portrait) {
        width: 30vh;
        max-height: 35vh;
      }
      @media (orientation: landscape) {
        width: 50vh;
        max-height: 59vh;
      }
    }
  }

  .ReactModal__Content--before-close & {
    opacity: 0.85;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 12px;
`;
