import styled from 'styled-components';

export const Wrapper = styled.section`
  color: ${p => p.theme.color.white100};
  display: flex;
  flex-direction: row;
  text-align: left;
  font-family: sans-serif;
  width: 100%;
  height: 285px;
  padding: 30px 0px;
  position: relative;
  margin-top: 40px;

  @media ${p => p.theme.device.desktop} {
    padding: 80px 44px;
    margin-top: 80px;
    height: 325px;
  }

  @media (max-width: 768px) {
    height: 240px;
  }
`;

export const BackgroundWrapper = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  z-index: -2;
`;

export const ColumnWrapper = styled.div`
  width: ${p => p.width || '100%'};
  display: flex;
  flex-direction: column;
  position: relative;

  > * {
    margin-bottom: 10px;
  }
    @media (max-width: 768px) {
    > * {
      margin-bottom: 5px;
    }
  }
    
`;

export const LogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;

  > :first-child {
    margin-right: 16px;
  }

  > h3 {
    color: ${p => p.theme.color.white100};
    cursor: pointer;
  }
`;

export const RowWrapper = styled.div`
  width: ${p => p.width || '100%'};
  display: flex;
  flex-direction: row;
  position: relative;
  margin: 20px 30px;
`;

export const ContactWrapper = styled.div`
  width: ${p => p.width || '100%'};
  display: flex;
  flex-direction: row;
  position: relative;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;