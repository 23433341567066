import styled from 'styled-components';

export const ContentWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${p => p.theme.color.white100};
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 6px;
  width: 100%;
  height: auto;
  margin: auto;
`;

export const HeaderRow = styled.div`
  position: absolute;
  top: 12px;
  width: 100%;
  min-height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${p => p.theme.color.black100};
`;

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  align-items: flex-start;
  padding: 16px;
`;

export const BodyWrapper = styled.div`
  padding: 0px 16px;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  justify-content: center;
`;

export const LoadingWrapper = styled.div`
  padding: 0px 16px;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
`;

export const ResultsWrapper = styled.div`
  padding: 0px 12px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  width: 100%;
  height: 100px;
  align-items: flex-start;
  justify-content: center;
  color: #002664;
  font-size: 20px;
`;

export const IndentedText = styled.div`
  white-space: pre-wrap; /* Preserve line breaks */
  text-align: ${p => p.textAlign || 'left'}; /* Ensure left alignment */
  /*padding-left: .5em;*/

  /* Indent only for wrapped lines */
  & > * {
    /*text-indent: -.8em;*/ /* Negative indent aligns with bullet */
    /*padding-left: .5em;*/ /* Indentation for wrapped lines */
  }

  /* Ensuring that each bullet point behaves independently */
  & > *:not(:last-child) {
    margin-bottom: 0.5em; /* Space between bullet points if needed */
  }
`;
