import styled from 'styled-components';
import { FixedSizeGrid } from 'react-window';
import wreath from './wreath.png';

export const Wrapper = styled.div`
  display: flex;
  flex: auto;
  flex-grow: 1;
  width: 100%; // ${p => p.bookPaneWidth};
  margin-top: -24px;
  height: 100%;

  /* hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;

  /* hide scrollbar for chrome, safari and opera */
  ::-webkit-scrollbar {
    display: none;
  }

  @media ${p => p.theme.device.tablet} {
    margin-left: 24px;
  }

  @media ${p => p.theme.device.desktop} {
    margin-top: 0;
  }
`;

export const Grid = styled(FixedSizeGrid)`
@media (max-width: 1369px) {
  /* hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;

  /* hide scrollbar for chrome, safari and opera */
  ::-webkit-scrollbar {
    display: none;
  }
}

  scroll-behavior: ${p => p.scrollStyle.current};
    @media (min-width: 1370px) {
    ::-webkit-scrollbar {
        width: 16px;  /* Adjust the width for your scrollbar */\
      }
      /* Custom style for the scrollbar thumb (the part you drag) */
      ::-webkit-scrollbar-thumb {
        background-color: rgba(0, 0, 0, 0.3);  /* Thumb color */
        border-radius: 8px;  /* Rounded corners for the thumb */
      }

      /* Custom style for the scrollbar track (the background part) */
      ::-webkit-scrollbar-track {
        background-color: rgba(0, 0, 0, 0.1);  /* Track color */
        border-radius: 8px;  /* Rounded corners for the track */
      }
  }
`;

export const LoadingWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100svh;
  align-items: center;
  justify-content: center;

  @media ${p => p.theme.device.tablet} {
    margin-left: 24px;
  }
`;

export const NoResultsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  font-family: 'Great Vibes', cursive;
  font-size: 2.5rem;
  color: #4b2e83;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.4);
  margin: 0;
  padding: 0;
  position: relative;

  &::before {
    content: '';
    position: absolute;
    width: 250px;
    height: 250px;
    background-image: url('${wreath}'); /* Add your wreath image URL here */
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1; /* Place it behind the text */
  }
`;
