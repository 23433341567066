import { defineMessages } from 'react-intl';

export default defineMessages({
  frenchBooks: {
    id: 'app.components.atoms.CheckItem.french-books',
    defaultMessage: `1,300 French children’s books.`,
  },
  readAloud: {
    id: 'app.components.atoms.CheckItem.read-aloud',
    defaultMessage: `100 French read aloud book videos.`,
  },
  songsLyrics: {
    id: 'app.components.atoms.CheckItem.songs-lyrics',
    defaultMessage: `100 French songs with lyrics.`,
  },
  creoleBooks: {
    id: 'app.components.atoms.CheckItem.creole-books',
    defaultMessage: `50 Creole children’s books.`,
  },
  spanishBooks: {
    id: 'app.components.atoms.CheckItem.spanish-books',
    defaultMessage: `500 Spanish children’s books.`,
  },
  access: {
    id: 'app.components.atoms.CheckItem.access',
    defaultMessage: `Access to language learning and translation apps.`,
  },
  resources: {
    id: 'app.components.atoms.CheckItem.resources',
    defaultMessage: `Updated with new resources every school year.`,
  },
  writtenRecorded: {
    id: 'app.components.atoms.CheckItem.written-recorded',
    defaultMessage: `Written or recorded in:`,
  },
  africa: {
    id: 'app.components.atoms.CheckItem.africa',
    defaultMessage: `Africa`,
  },
  middleEast: {
    id: 'app.components.atoms.CheckItem.middle-east',
    defaultMessage: `Middle East`,
  },
  cambodia: {
    id: 'app.components.atoms.CheckItem.cambodia',
    defaultMessage: `Cambodia`,
  },
  india: {
    id: 'app.components.atoms.CheckItem.india',
    defaultMessage: `India`,
  },
  vietnam: {
    id: 'app.components.atoms.CheckItem.vietnam',
    defaultMessage: `Vietnam`,
  },
  france: {
    id: 'app.components.atoms.CheckItem.france',
    defaultMessage: `France`,
  },
  canada: {
    id: 'app.components.atoms.CheckItem.canada',
    defaultMessage: `Canada`,
  },
  unitedStates: {
    id: 'app.components.atoms.CheckItem.united-states',
    defaultMessage: `United States`,
  },
  materials: {
    id: 'app.components.atoms.CheckItem.materials',
    defaultMessage: `All books are open-sourced – available for anyone to use for free.`,
  },
  downloaded: {
    id: 'app.components.atoms.CheckItem.downloaded',
    defaultMessage: `Once installed, the digital library can be used without the internet.`,
  },
  circumstances: {
    id: 'app.components.atoms.CheckItem.circumstances',
    defaultMessage: `Haitian children can read about children from other
    developing countries that look like them and live under similar circumstances.`,
  },

  used: {
    id: 'app.components.atoms.CheckItem.used',
    defaultMessage: `The library app can be used:`,
  },
  tablets: {
    id: 'app.components.atoms.CheckItem.tablets',
    defaultMessage: `On tablets`,
  },
  laptops: {
    id: 'app.components.atoms.CheckItem.laptops',
    defaultMessage: `On Laptops and Desktops`,
  },
  desktops: {
    id: 'app.components.atoms.CheckItem.desktops',
    defaultMessage: `On desktops`,
  },
  phones: {
    id: 'app.components.atoms.CheckItem.phones',
    defaultMessage: `Some Tablets and Phones`,
  },
  installing: {
    id: 'app.components.atoms.CheckItem.installing',
    defaultMessage: `Installing:`,
  },
  internet: {
    id: 'app.components.atoms.CheckItem.internet',
    defaultMessage: `An internet connection is needed to download the app.`,
  },
  minutes: {
    id: 'app.components.atoms.CheckItem.minutes',
    defaultMessage: `It only takes a few minutes.`,
  },
  anywhere: {
    id: 'app.components.atoms.CheckItem.anywhere',
    defaultMessage: `The digital library can then be used at schools or anywhere without the internet.`,
  },
  annual: {
    id: 'app.components.atoms.CheckItem.annual',
    defaultMessage: `An internet connection is needed to update the app, which we anticipate to be once a year.`,
  },
  update: {
    id: 'app.components.atoms.CheckItem.update',
    defaultMessage: `Updates will include more books and videos.`,
  },

  noAccess: {
    id: 'app.components.atoms.CheckItem.no-access',
    defaultMessage: `Since most Haitian schools do not have libraries, children have very little (or no) access to books. But…`,
  },
  relies: {
    id: 'app.components.atoms.CheckItem.relies',
    defaultMessage: `Our digital library relies on open source materials – books and videos that are available for anyone to
    use for free under creative commons licenses.`,
  },
  explosion: {
    id: 'app.components.atoms.CheckItem.explosion',
    defaultMessage: `In the last few years there has been an explosion of new open source books written for children by
    authors all around the world.`,
  },
  canDownload: {
    id: 'app.components.atoms.CheckItem.can-download',
    defaultMessage: `These books can be read on devices such as laptops, desktops, tablets and smartphones.`,
  },
  cheaper: {
    id: 'app.components.atoms.CheckItem.cheaper',
    defaultMessage: `Tablets continue to get cheaper while also having more storage capacity and longer battery life.`,
  },
  openSource: {
    id: 'app.components.atoms.CheckItem.open-source',
    defaultMessage: `Open source material, combined with cheaper and better tablets, makes it possible to get thousands of
    books into the hands of children who otherwise would have none.`,
  },

  nonprofit: {
    id: 'app.components.atoms.CheckItem.nonprofit',
    defaultMessage: `The Reading Room is a Connecticut based non-profit dedicated to providing access to books and to
    building a culture of reading in Haiti. We have:`,
  },
  working: {
    id: 'app.components.atoms.CheckItem.working',
    defaultMessage: `Been working with Haitian Schools for over 30 years.`,
  },
  experience: {
    id: 'app.components.atoms.CheckItem.experience',
    defaultMessage: `Used that experience to develop our free digital library app, which allows schools to get over 2,000
    books and other resources into the hands of students.`,
  },
  flash: {
    id: 'app.components.atoms.CheckItem.flash',
    defaultMessage: `Developed portable “flash libraries”, which allow schools to quickly transform classrooms or
    lunchrooms into comfortable libraries with 500-1,000 books.`,
  },
  camps: {
    id: 'app.components.atoms.CheckItem.camps',
    defaultMessage: `Organized “book camps” where teachers and students can come together for a month in the summer
    to read, write, play learning related games and build their literacy skills.`,
  },
  training: {
    id: 'app.components.atoms.CheckItem.training',
    defaultMessage: `Provided training to Haitian teachers so they can use books and other library materials more
    effectively.`,
  },

  overhead: {
    id: 'app.components.atoms.CheckItem.overhead',
    defaultMessage: `The Reading Room has minimal overhead - operating out of a 1,000 square foot facility in our
    home that contains an office, print shop and storage space.`,
  },
  management: {
    id: 'app.components.atoms.CheckItem.management',
    defaultMessage: `There are no management or administrative salaries.`,
  },
  volunteers: {
    id: 'app.components.atoms.CheckItem.volunteers',
    defaultMessage: `The app was developed and is maintained by an amazing group of dedicated technology volunteers.`,
  },
});
