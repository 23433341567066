/* eslint-disable */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';

import { Typography } from 'components/atoms/Typography';
import { Button } from 'components/atoms/Button';
import { BaseModalDownload } from 'components/molecules/Modal';
import { DownloadBar } from 'components/organisms/DownloadBar';
import messages from 'containers/Home/messages';
import { ThreeDots } from 'react-loader-spinner';
import {
  ResultsWrapper,
  ButtonWrapper,
  BodyWrapper,
  LoadingWrapper,
  HeaderRow,
} from './wrappers';

// Define the link component outside to avoid re-creating it every render
const renderLink = chunks => (
  <a
    href="https://S420L.club/static/uninstall_instructions.pdf"
    target="_blank"
    rel="noopener noreferrer"
  >
    {chunks}
  </a>
);

export const DownloadModal = ({
  isOpen,
  onClose,
  filterModal,
  message,
  calculatingDownload,
  confirmDownload,
  downloadSelected,
  setDownloadSelected,
  results,
  filter,
  isMobile,
  setIsMobileMenuOpen,
  confirmSelection,
  afterDownload,
  ...props
}) => {
  const timeEstimate = !results?.count
    ? 0
    : Math.max(2, Math.round(results.count / 60));

  return (
    <BaseModalDownload {...{ isOpen, onClose, ...props }}>
      {!downloadSelected && !calculatingDownload && !afterDownload && (
        <HeaderRow>
          <FormattedMessage {...messages.downloadHeader} />
        </HeaderRow>
      )}
      {afterDownload ? (
        <BodyWrapper>
          <Typography variant="h2" label="Beginning download..." />
          <br />
          <Typography
            variant="text-lg"
            label={`If an install window does not appear, refresh the page and try again.`}
          />
          <br />
          <Typography
            variant="text-lg"
            label={`After install begins, close your web browser and open the app directly from your device. If a window pops up automatically after installing, close it out and open app from device.`}
          />
          <br />
        </BodyWrapper>
      ) : calculatingDownload ? (
        <LoadingWrapper>
          <ThreeDots width="100" color="#002664" ariaLabel="loading" visible />
        </LoadingWrapper>
      ) : !downloadSelected ? (
        <BodyWrapper>
          <DownloadBar {...{ isMobile, setIsMobileMenuOpen }} />
        </BodyWrapper>
      ) : (
        <BodyWrapper>
          {/* We will need to add translation support for this section */}
          <Typography variant="h2" label="Ready to download!" />
          <ResultsWrapper>
            <Typography
              variant="h3"
              style={{ fontSize: '22px' }}
              label={`${results.count} books`}
            />
            <Typography
              variant="h3"
              style={{ fontSize: '22px' }}
              label={`${timeEstimate} - ${Math.floor(
                timeEstimate * 1.66,
              )} minutes`}
            />
          </ResultsWrapper>
        </BodyWrapper>
      )}
      {!afterDownload &&
        (!downloadSelected ? (
          <ButtonWrapper>
            <Button
              variant="primary"
              label={<FormattedMessage {...messages.continue} />}
              onClick={confirmSelection}
            />
          </ButtonWrapper>
        ) : (
          <ButtonWrapper>
            <Button
              variant="primary"
              label={<FormattedMessage {...messages.download} />}
              onClick={confirmDownload}
            />
            <Button
              variant="ghost"
              label={<FormattedMessage {...messages.goBack} />}
              onClick={() => setDownloadSelected(false)}
            />
          </ButtonWrapper>
        ))}
    </BaseModalDownload>
  );
};

DownloadModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  calculatingDownload: PropTypes.bool.isRequired,
  confirmDownload: PropTypes.func.isRequired,
  downloadSelected: PropTypes.bool.isRequired,
  setDownloadSelected: PropTypes.func.isRequired,
  results: PropTypes.object.isRequired,
  afterDownload: PropTypes.bool.isRequired,
};

export default DownloadModal;
