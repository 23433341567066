import styled from 'styled-components';

//border-radius: 25px;
//padding: 24px;
//margin: 24px auto;
export const Wrapper = styled.div`
  position: relative;
  background-color: ${p => p.theme.color.white100};

  display: flex;
  flex-direction: column;
  flex: auto;
  align-items: center;
  justify-content: flex-start;

  width: 100%;
  height: 100%;
`;

export const Header = styled.div`
  width: 100%;
  margin-bottom: 4px;
  min-height: 12px;
`;

export const HeaderRow = styled.div`
  position: relative;
  width: 100%;
  min-height: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${p => p.theme.color.black100};

  * {
    padding: 2px;
    font-size: 12px;
  }
`;

export const Scroll = styled.div`
  width: 100%;
  height: 100%;
  max-height: 66vh;
  overflow-y: scroll;

  /* hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none;

  /* hide scrollbar for chrome, safari and opera */
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const Accordion = styled.div`
  border-top: 1px solid ${p => p.theme.color.black300};
  width: calc(100% - 8px);
  min-height: ${p => (p.open ? 'auto' : '50px')};
  margin-bottom: ${p => (p.open ? '16px' : '0px')};
  height: auto;
  transition: all 300ms ease;
`;

export const TitleWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: ${p => p.theme.color.red500};
  text-align: left;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  width: 100%;
  height: 50px;
  padding: 16px 0px;
  cursor: pointer;
`;

export const Caret = styled.div`
  position: absolute;
  right: 0px;
  top: 14px;
  transform: ${p => !p.open && 'rotate(-90deg)'};
  transition: all 300ms ease;
`;
