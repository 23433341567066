import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectLocale } from 'data/language/selectors';
import { actions as languageActions } from 'data/language/slice';
import { selectFilterModal } from 'data/filter/selectors';
import { actions as filterActions } from 'data/filter/slice';
import { selectOpenBook } from 'data/book/selectors';
import { actions as bookActions } from 'data/book/slice';

import { CardList } from 'components/organisms/CardList';
import { FiltersBar } from 'components/organisms/FiltersBar';
import { Button } from 'components/atoms/Button';
import { useMediaQuery } from 'shared/hooks/useMediaQuery';
import { getPWADisplayMode } from 'shared/functions/utils';
import LangModal from 'containers/Nav/LangModal';
import BookModal from './BookModal';
import FilterModal from './FilterModal';
import {
  BooksWrapper,
  FiltersWrapper,
  LangButtonWrapper,
  Wrapper,
} from './wrappers';

const Library = () => {
  const filterModal = useSelector(selectFilterModal);
  const openBook = useSelector(selectOpenBook);
  const dispatch = useDispatch();
  const display = getPWADisplayMode();
  const isMobile = useMediaQuery('(max-width: 768px)');
  const locale = useSelector(selectLocale);
  const localeString = loc => {
    switch (loc) {
      case 'en-UK':
        return 'EN';
      case 'fr-FR':
        return 'FR';
      case 'cr-HT':
        return 'HT';
      case 'es-ES':
        return 'ES';
      default:
        return '';
    }
  };
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [changeLang, setChangeLang] = useState(false);
  const changeLocale = newLocale => {
    dispatch(languageActions.applyLocale(newLocale));
  };

  return (
    <Wrapper id="library-wrapper" marginTop="0px">
      {display !== 'browser' && (
        <LangButtonWrapper>
          <Button
            variant="icon-sm"
            iconLeft={locale}
            iconRight="down"
            onClick={() => setChangeLang(true)}
            label={localeString(locale)}
          />
        </LangButtonWrapper>
      )}
      {!isMobile || isMobileMenuOpen ? (
        <FiltersWrapper>
          <FiltersBar {...{ isMobile, setIsMobileMenuOpen }} />
        </FiltersWrapper>
      ) : (
        <Button
          variant="icon-lg"
          iconLeft="menu"
          style={{ position: 'absolute', top: 10, left: 10, zIndex: 1000 }}
          onClick={() => setIsMobileMenuOpen(true)}
        />
      )}
      {(!isMobile || (isMobile && !isMobileMenuOpen)) && (
        <BooksWrapper>
          <CardList />
        </BooksWrapper>
      )}

      <FilterModal
        isOpen={!!filterModal}
        onClose={() => dispatch(filterActions.applyModal(''))}
        filter={filterModal}
      />
      {!!openBook && (
        <BookModal
          isOpen={!!openBook?.pdf}
          onClose={() => dispatch(bookActions.setOpenBook(null))}
          {...{ openBook }}
        />
      )}
      <LangModal
        isOpen={changeLang}
        onClose={() => setChangeLang(false)}
        {...{ changeLocale }}
      />
    </Wrapper>
  );
};

export default Library;
