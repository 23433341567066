import { defineMessages } from 'react-intl';

export default defineMessages({
  header: {
    id: 'app.containers.Home.header',
    defaultMessage:
      'We bring the joy and power of reading to children in Haiti',
  },
  pitch1: {
    id: 'app.containers.Home.pitch1',
    defaultMessage: `
      Our free app allows schools to have “digital libraries” where children can read free open source books in Haitian Creole, French, English and Spanish.
    `,
  },
  pitch2: {
    id: 'app.containers.Home.pitch2',
    defaultMessage: `
      Use on the Internet - Click “Explore our Library App” and search for books.
    `,
  },
  pitch3: {
    id: 'app.containers.Home.pitch3',
    defaultMessage: `
      Use without the Internet - for access to books at remote schools or locations.  Click “Install our Library App” and follow the instructions to download some or all of the books.
    `,
  },
  explore: {
    id: 'app.containers.Home.explore',
    defaultMessage: 'Explore our library app',
  },
  continue: {
    id: 'app.containers.Home.continue',
    defaultMessage: 'Continue',
  },
  goBack: {
    id: 'app.containers.Home.goBack',
    defaultMessage: 'Go Back',
  },
  download: {
    id: 'app.containers.Home.download',
    defaultMessage: 'Download',
  },
  back: {
    id: 'app.containers.Home.back',
    defaultMessage: 'back',
  },
  next: {
    id: 'app.containers.Home.next',
    defaultMessage: 'next',
  },
  downloadHeader: {
    id: 'app.containers.Home.downloadHeader',
    defaultMessage: 'SELECT BOOKS TO DOWNLOAD',
  },
  installHeader: {
    id: 'app.containers.Home.installHeader',
    defaultMessage: 'INSTALL APP WITH BOOK SELECTION',
  },
  install: {
    id: 'app.containers.Home.install',
    defaultMessage: 'Install our library app',
  },
  userGuide: {
    id: 'app.containers.Home.user-guide',
    defaultMessage: 'User Guide',
  },
  tileHeader: {
    id: 'app.containers.Home.tile-header',
    defaultMessage: 'Our Free Library App Has:',
  },
  tileBooks: {
    id: 'app.containers.Home.tile-books',
    defaultMessage: `Over 2,000 Books in Haitian Creole, French, Spanish, and English`,
  },
  tileWorld: {
    id: 'app.containers.Home.tile-world',
    defaultMessage: 'From All Over the World',
  },
  tileHaiti: {
    id: 'app.containers.Home.tile-haiti',
    defaultMessage: 'For Haitian Schools',
  },
  donateHeader: {
    id: 'app.containers.Home.donate-header',
    defaultMessage: 'Help Support Our Mission',
  },
  donate: {
    id: 'app.containers.Nav.donate',
    defaultMessage: 'Donate',
  },
  tabletHeader: {
    id: 'app.containers.Home.tablet-header',
    defaultMessage: `Free Library App`,
  },
  technologyHeader: {
    id: 'app.containers.Home.technology-header',
    defaultMessage: `Technology is a Game Changer`,
  },
  thriveHeader: {
    id: 'app.containers.Home.thrive-header',
    defaultMessage: `To Thrive as Readers, Haitian Children Need`,
  },
  meetHeader: {
    id: 'app.containers.Home.meet-header',
    defaultMessage: `How We Meet Those Needs`,
  },
  teamHeader: {
    id: 'app.containers.Home.team-header',
    defaultMessage: `Our Team`,
  },
  teamDirector: {
    id: 'app.containers.Home.team-director',
    defaultMessage: `Director`,
  },
  teamCofounder: {
    id: 'app.containers.Home.team-cofounder',
    defaultMessage: `Co-founder`,
  },
  learn: {
    id: 'app.containers.Home.learn',
    defaultMessage: `Learn More`,
  },
  futureHeader: {
    id: 'app.containers.Home.future-header',
    defaultMessage: `Let's Create a Better Future for Children in Haiti`,
  },
  future: {
    id: 'app.containers.Home.future',
    defaultMessage: `We need funds to continue to develop and expand The Reading Room digital library app. We are constantly adding new books,
      read aloud videos, song videos with lyrics and other material to the app. We believe it is transformational for children to have access to thousands of
      reading resources right at their fingertips.
      This is all in fulfilment of our mission to bring the joy and power of reading to children in Haiti.
    `,
  },
});
