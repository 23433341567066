/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { storeData, getLibraryCount, getFilteredBooks, getFolderList, storeFolderlist } from 'data/database';
import { CircularProgressbar } from 'react-circular-progressbar';
import { Icon } from 'components/atoms/Icon';
import { Typography } from 'components/atoms/Typography';
import { FormattedMessage } from 'react-intl';
import { Helmet } from 'react-helmet-async';
import { Routes, Route, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { actions as filterActions } from 'data/filter/slice';
import { selectFilters } from 'data/filter/selectors';
import theme from 'shared/themePrimary';
import Nav from 'containers/Nav';
import Home from 'containers/Home';
import Library from 'containers/Library';
import Staff from 'containers/Staff';
import HelpOut from 'containers/HelpOut';
import { getPWADisplayMode } from 'shared/functions/utils';
import { useMediaQuery } from 'shared/hooks/useMediaQuery';
import { ScrollToTop } from './utils';
import { LoaderWrapper, OfflineMessage } from './wrappers';
import messages from './messages';

export const App = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const filters = useSelector(selectFilters);
  const isMobile = useMediaQuery('(max-width: 768px)');
  const browser = getPWADisplayMode() === 'browser';// && !isMobile;
  const showNav = browser && location.pathname !== '/library';
  const [progress, setProgress] = useState(0);
  const [currentDataLength, setCurrentDataLength] = useState(null);
  const [lengthFolderList, setLengthFolderList] = useState(null);
  const [done, setDone] = useState(null);

  const index=10;

  useEffect(() => {
    const fetchData = async () => {
      console.log(`Mobile: ${isMobile} || Browser: ${browser}`);
      const folderList = await getFolderList(true, false);
      try {
        if(!lengthFolderList){
            const folderList = await getFolderList(true, false);
            const done = await getFolderList(false, true);
            console.log(`initializing DONE to: ${done}`);
            setDone(done);
            setLengthFolderList(folderList.count);
            const startingDataLength = await getLibraryCount();
            setCurrentDataLength(startingDataLength);
            console.log("STARTING data LENGTH:");
            console.log(startingDataLength);
            return;
        }

          // If we already have all the data, no need to query for more
          if (currentDataLength>=lengthFolderList || done) {
            console.log("DOWNLOAD FINISHED!!!");
            const result = await storeFolderlist(false, true); // set to done so it doesn't retry the download and break offline
            console.log(`value of DONE: ${result}`);
            initializeBookQueueApp();
            setProgress(100);
          } else {
            console.log(`currentDataLength: ${currentDataLength}  || folderlist length: ${lengthFolderList}`);
            const response = await fetch('https://notisis.life/api/download_folders', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              body: JSON.stringify({folder_list: folderList.folder_list, offset: currentDataLength, index: index})
            });
            const data = await response.json();
            console.log(data);
            
            await storeData(data);
            setCurrentDataLength(currentDataLength + index);
            const updatedProgress = ((currentDataLength + index) / lengthFolderList) * 100;
            console.log(`book download progress: ${updatedProgress}%`);
            setProgress(updatedProgress);
            if(updatedProgress>=100){
              const result = await storeFolderlist(false, true); // set to done so it doesn't retry the download and break offline
              console.log(`value of DONE: ${result}`);
              setDone(result);
            }
            initializeBookQueueApp();
          }
      } catch (error) {
        console.error('Error fetching or processing data:', error);
      }
    };
    const initializeBookQueueApp = async () => {
      const bookQueue = await getFilteredBooks(filters, 0, 10);
      dispatch(filterActions.setBookQueue(bookQueue));
    };
    const initializeBookQueueWebsite = async () => {
      try {
        const response = await fetch('https://S420L.club/api/get_books', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({range: [1, 24]})
          //body: JSON.stringify({code: 5649})
        });
    
        if (!response.ok) {
          throw new Error('Failed to fetch data');
        }
    
        const bookQueue = await response.json();
        console.log("BOOK QUEUE!!");
        console.log("______________________________")
        console.log(bookQueue);
        console.log("______________________________")
        dispatch(filterActions.setBookQueue(bookQueue));
      } catch (error) {
        console.error(error);
        // Handle errors here
      }
    };
    if (browser) {
      initializeBookQueueWebsite();
    } else if ((currentDataLength<lengthFolderList) || !lengthFolderList) {
      fetchData();
    } else {
      console.log('finished fetching books');
      initializeBookQueueApp();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, currentDataLength]);

  return (
    <>
      <Helmet
        titleTemplate="%s - The Reading Room"
        defaultTitle="The Reading Room"
        htmlAttributes={{ lang: 'en-UK' }}
      >
        <meta name="description" content="The Reading Room application" />
      </Helmet>
      {showNav && <Nav />}
      <ScrollToTop />

      <LoaderWrapper>
        {progress < 100 && !browser ? (
          <CircularProgressbar
            value={progress}
            text={`${Math.round(progress)}%`}
            styles={{
              root: {
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              },
              text: {
                // Text color
                fill: theme.color.black,
                // Text size
                fontSize: '32px',
                dominantBaseline: 'central',
                textAnchor: 'middle',
              },
              // Customize the path, i.e. the "completed progress"
              path: {
                // Path color
                stroke: theme.color.blue500,
                // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                strokeLinecap: 'butt',
                // Customize transition animation
                transition: 'stroke-dashoffset 0.5s ease 0s',
                // Rotate the path
                transform: 'rotate(0.25turn)',
                transformOrigin: 'center center',
              },
              // Customize the circle behind the path, i.e. the "total progress"
              trail: {
                // Trail color
                stroke: theme.color.red200,
                // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                strokeLinecap: 'butt',
                // Rotate the trail
                transform: 'rotate(0.25turn)',
                transformOrigin: 'center center',
              },
            }}
          >
          </CircularProgressbar>
        ) : !browser ? (
          <OfflineMessage>
            <Icon variant="check-red" />
            <Typography
              variant="text-sm"
              as="p"
              label={<FormattedMessage {...messages.offline} />}
            />
          </OfflineMessage>
        ) : ""}
      </LoaderWrapper>
      <Routes>
        <Route index element={browser ? <Home /> : <Library />} />
        {browser && (
          <>
            <Route path="library" element={<Library />} />
            <Route path="about-us" element={<Staff />} />
            <Route path="how-to-help" element={<HelpOut />} />
          </>
        )}
      </Routes>
    </>
  );
};
