import { defineMessages } from 'react-intl';

export default defineMessages({
  loading: {
    id: 'app.containers.BookViewer.loading',
    defaultMessage: 'loading...',
  },
  by: {
    id: 'app.components.molecules.Card.card-author',
    defaultMessage: 'by {author}',
  },

  beginning: {
    id: 'app.components.molecules.FilterOptions.level-beginning',
    defaultMessage: 'Beginning reader',
  },
  early: {
    id: 'app.components.molecules.FilterOptions.level-early',
    defaultMessage: 'Early elementary',
  },
  middle: {
    id: 'app.components.molecules.FilterOptions.level-middle',
    defaultMessage: 'Middle elementary',
  },
  late: {
    id: 'app.components.molecules.FilterOptions.level-late',
    defaultMessage: 'Late elementary',
  },
  earlyMiddle: {
    id: 'app.components.molecules.FilterOptions.level-earlyMiddle',
    defaultMessage: 'Early middle school',
  },
  lateMiddle: {
    id: 'app.components.molecules.FilterOptions.level-lateMiddle',
    defaultMessage: 'Late middle school',
  },
  high: {
    id: 'app.components.molecules.FilterOptions.level-high',
    defaultMessage: 'High school',
  },

  storyweaver: {
    id: 'app.components.molecules.FilterOptions.source-storyweaver',
    defaultMessage: 'Storyweaver',
  },
  africanStorybook: {
    id: 'app.components.molecules.FilterOptions.source-africanStorybook',
    defaultMessage: 'African Storybook',
  },
  davidRevoy: {
    id: 'app.components.molecules.FilterOptions.source-davidRevoy',
    defaultMessage: 'David Revoy',
  },
  bookdash: {
    id: 'app.components.molecules.FilterOptions.source-bookdash',
    defaultMessage: 'Bookdash',
  },
  mustardSeed: {
    id: 'app.components.molecules.FilterOptions.source-mustardSeed',
    defaultMessage: 'Mustard Seed',
  },
  bibleForChildren: {
    id: 'app.components.molecules.FilterOptions.source-bibleForChildren',
    defaultMessage: 'Bible For Children',
  },
  unesco: {
    id: 'app.components.molecules.FilterOptions.source-unesco',
    defaultMessage: 'UNESCO',
  },
  storybooksCanada: {
    id: 'app.components.molecules.FilterOptions.source-storybooksCanada',
    defaultMessage: 'Storybooks Canada',
  },
  bookbot: {
    id: 'app.components.molecules.FilterOptions.source-bookbot',
    defaultMessage: 'Bookbot',
  },
  bookSprint: {
    id: 'app.components.molecules.FilterOptions.source-bookSprint',
    defaultMessage: 'Book Sprint',
  },

  topic: {
    id: 'app.components.molecules.Card.card-topic',
    defaultMessage: 'Topic',
  },
  words: {
    id: 'app.components.molecules.Card.card-words',
    defaultMessage: '{total_words} words',
  },
  fiction: {
    id: 'app.components.molecules.FilterOptions.fiction',
    defaultMessage: 'Fiction',
  },
  nonFiction: {
    id: 'app.components.molecules.FilterOptions.nonFiction',
    defaultMessage: 'Non-fiction',
  },
  graphicNovels: {
    id: 'app.components.molecules.FilterOptions.graphicNovels',
    defaultMessage: 'Graphic Novels',
  },
  bookSeries: {
    id: 'app.components.molecules.FilterOptions.bookSeries',
    defaultMessage: 'Book Series',
  },
});
