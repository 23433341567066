import React, { useCallback, useRef } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { selectBookQueue } from 'data/filter/selectors';

import InfiniteLoader from 'react-window-infinite-loader';
import { ThreeDots } from 'react-loader-spinner';
import { useMediaQuery } from 'shared/hooks/useMediaQuery';
import { debounce } from 'shared/functions/debounce';
import { Card } from 'components/molecules/Card';
import { Grid } from '../wrappers';
import { LoadingWrapper } from './wrappers';

export const InfiniteLoad = ({
  bookPaneHeight,
  bookPaneWidth,
  hasNextPage,
  loadNextPage,
}) => {
  const bookQueue = useSelector(selectBookQueue);
  const scrollStyle = useRef('auto');
  const scrollOffset = useRef(0);
  const isPhone = useMediaQuery('(max-width: 768px)');
  const cardHeight = isPhone ? 480 : 500;

  // If there are more items to be loaded then add an extra row to hold a loading indicator.
  const itemCount = hasNextPage ? bookQueue.length + 1 : bookQueue.length;

  // Only load 1 page of items at a time.
  // Pass an empty callback to InfiniteLoader in case it asks us to load more than once.
  const loadMoreItems = /* isNextPageLoading ? () => {} : */ loadNextPage;

  // Every row is loaded except for our loading indicator row.
  const isItemLoaded = index => !hasNextPage || index < bookQueue.length;

  const scrollCheck = useCallback(
    scrollData => {
      if (!scrollOffset) return;
      scrollOffset.current = scrollData;
    },
    [scrollOffset],
  );

  return (
    <InfiniteLoader
      isItemLoaded={isItemLoaded}
      itemCount={itemCount}
      loadMoreItems={loadMoreItems}
      threshold={2}
    >
      {({ onItemsRendered, ref }) => {
        const newItemsRendered = gridData => {
          const useOverscanForLoading = true;
          const {
            visibleRowStartIndex,
            visibleRowStopIndex,
            visibleColumnStopIndex,
            overscanRowStartIndex,
            overscanRowStopIndex,
            overscanColumnStopIndex,
          } = gridData;

          const endCol =
            (useOverscanForLoading
              ? overscanColumnStopIndex
              : visibleColumnStopIndex) + 1;

          const startRow = useOverscanForLoading
            ? overscanRowStartIndex
            : visibleRowStartIndex;
          const endRow = useOverscanForLoading
            ? overscanRowStopIndex
            : visibleRowStopIndex;

          const visibleStartIndex = startRow * endCol;
          const visibleStopIndex = endRow * endCol;

          onItemsRendered({
            visibleStartIndex,
            visibleStopIndex,
          });
          if (scrollStyle.current === 'smooth') {
            scrollStyle.current = 'auto';
          }
        };
        return (
          <Grid
            id="infinite-grid"
            columnCount={2}
            columnWidth={bookPaneWidth / 2}
            height={bookPaneHeight}
            rowCount={Math.round(itemCount / 2)}
            rowHeight={cardHeight}
            width={bookPaneWidth}
            itemSize={cardHeight}
            onScroll={data => debounce(scrollCheck(data.scrollTop), 100)}
            overscanRowCount={2}
            onItemsRendered={newItemsRendered}
            ref={ref}
            scrollStyle={scrollStyle}
            useIsScrolling
            style={{
              overflowX: 'hidden',
            }}
          >
            {({ columnIndex, rowIndex, isScrolling, style }) => {
              if (isScrolling) {
                scrollStyle.current = 'smooth';
              }
              const index = rowIndex * 2 + columnIndex;
              const paddingTop = index < 2 ? '44px' : 0;
              if (index > itemCount - 1) return null;
              let content;
              if (!isItemLoaded(index)) {
                content =
                  columnIndex === 0 ? (
                    <LoadingWrapper>
                      <ThreeDots
                        width="100"
                        color="#002664"
                        ariaLabel="loading"
                        visible
                      />
                    </LoadingWrapper>
                  ) : null;
              } else {
                content = <Card book={bookQueue[index]} />;
              }
              return (
                <div style={{ ...style, paddingTop }} key={index}>
                  {content}
                </div>
              );
            }}
          </Grid>
        );
      }}
    </InfiniteLoader>
  );
};

InfiniteLoad.propTypes = {
  bookPaneHeight: PropTypes.number.isRequired,
  bookPaneWidth: PropTypes.number.isRequired,
  hasNextPage: PropTypes.bool.isRequired,
  loadNextPage: PropTypes.func.isRequired,
};
