import styled, { css } from 'styled-components';

/* Display Block */
const buttonDisplayBlock = css`
  display: flex;
  width: 100%;
`;

/* Display Inline */
const buttonDisplayInline = css`
  display: inline-flex;
`;

/* Align Left */
const buttonAlignLeft = css`
  text-align: left;
  justify-content: flex-start;
`;

/* Align Center */
const buttonAlignCenter = css`
  text-align: center;
  justify-content: center;
`;

/* Align Right */
const buttonAlignRight = css`
  text-align: right;
  justify-content: flex-end;
`;

/* Extra Small Size */
const buttonSizeXs = css`
  padding: ${p => (p.hideLabel ? '9px' : '4px 16px')};
  border-radius: 6px;
  border-width: 2px;
  font-family: ${p => p.theme.font.sans};
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  letter-spacing: 0.03em;

  > svg {
    height: 14px;
  }
`;

/* Small Size */
const buttonSizeSm = css`
  padding: ${p => (p.hideLabel ? '10px' : '4px 16px')};
  border-radius: 6px;
  border-width: 2px;
  font-family: ${p => p.theme.font.sans};
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  letter-spacing: 0.02em;

  > svg {
    height: 16px;
  }

  @media ${p => p.theme.device.desktop} {
    font-size: 18px;
  }
`;

/* Medium Size */
const buttonSizeMd = css`
  padding: ${p => (p.hideLabel ? '10px' : '4px 16px')};
  border-radius: 6px;
  border-width: 2px;
  font-family: ${p => p.theme.font.sans};
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;

  > svg {
    height: 20px;
  }
  
  @media (max-width: 768px) {
    line-height: 18px;
  }
`;

/* Large Size */
const buttonSizeLg = css`
  padding: ${p => (p.hideLabel ? '14px' : '12px 24px')};
  border-radius: 6px;
  border-width: 2px;
  font-family: ${p => p.theme.font.sans};
  font-weight: 600;
  font-size: 16px;

  > svg {
    height: 20px;
  }
`;

/* Primary Variant */
const buttonVariantPrimary = css`
  border-color: ${p => p.theme.color.red500};
  border-style: solid;
  border-radius: 100px;
  background-color: ${p => p.theme.color.red500};
  color: ${p => p.theme.color.white100};
  height: 44px;

  &:hover:not(:disabled) {
    background-color: ${p => p.theme.color.red200};
    border-color: ${p => p.theme.color.red500};
  }

  &:disabled {
    opacity: 0.4;
  }

  @media ${p => p.theme.device.desktop} {
    min-width: 220px;
    height: 56px;
  }
`;

//background-color: ${p => p.theme.color.red500};
//color: ${p => p.theme.color.white100};
/* Download Variant */
const buttonVariantDownload = css`
  border-color: pink;
  border-style: solid;
  border-radius: 12px;
  background-color: #5DADE2;
  color: ${p => p.theme.color.white100};
  height: 44px;

  &:hover:not(:disabled) {
    background: linear-gradient(to bottom, #3a7bbf, #2a5b9f);
    border: 2px solid #50C878; /* Darker emerald color for the border */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5); /* Adds shadow for 3D effect */
    border-radius: 12px; /* Maintain rounded corners */
    background-color: #009B77; /* Optional: background color to complement the border */
  }

  &:disabled {
    opacity: 0.4;
  }

  @media ${p => p.theme.device.desktop} {
    min-width: 220px;
    height: 56px;
  }
`;

/* Ghost Variant */
const buttonVariantGhost = css`
  border-color: ${p => p.theme.color.red500};
  border-style: solid;
  border-radius: 100px;
  background-color: ${p => p.theme.color.white100};
  color: ${p => p.theme.color.red500};
  height: 44px;

  &:hover:not(:disabled) {
    background-color: ${p => p.theme.color.red200};
    color: ${p => p.theme.color.white100};
  }

  &:disabled {
    opacity: 0.4;
  }

  > svg path {
    fill: ${p => p.theme.color.red500};
  }

  @media ${p => p.theme.device.desktop} {
    min-width: 220px;
    height: 56px;
  }
`;

/* Ghost (Light) Variant */
const buttonVariantGhostLight = css`
  border-color: ${p => p.theme.color.white100};
  border-style: solid;
  background-color: transparent;
  color: ${p => p.theme.color.white100};

  &:hover:not(:disabled) {
    background-color: ${p => p.theme.color.blue200}33;
  }

  &:disabled {
    opacity: 0.4;
  }

  > svg path {
    fill: ${p => p.theme.color.white100};
  }
`;

/* Text Variant */
const buttonVariantText = css`
  border-color: ${p => (p.pressed ? p.theme.color.red200 : 'transparent')};
  border-style: solid;
  border-radius: 6px;
  background-color: ${p => (p.pressed ? p.theme.color.red200 : 'transparent')};
  color: ${p => p.theme.color.red500};
  font-weight: 500;

  &:hover:not(:disabled) {
    background-color: ${p => p.theme.color.red100};
    border-color: ${p => p.theme.color.red100};
  }

  &:disabled {
    opacity: 0.4;
  }

  > svg path {
    fill: ${p => p.theme.color.red500};
  }
`;

/* Text (Light) Variant */
const buttonVariantTextLight = css`
  border-color: transparent;
  border-style: solid;
  border-radius: 6px;
  background-color: transparent;
  color: ${p => p.theme.color.white100};

  &:hover:not(:disabled) {
    background-color: ${p => p.theme.color.blue200}33;
  }

  &:disabled {
    opacity: 0.4;
  }

  > svg path {
    fill: ${p => p.theme.color.red500};
  }
`;

/* Link Variant */
const buttonVariantLink = css`
  background-color: transparent;
  color: ${p => p.theme.color.black200};
  padding: 0;
  border: 0;
  font-weight: 500;
  z-index: 1000;

  &:hover:not(:disabled) {
    text-decoration: underline;
  }

  &:disabled {
    opacity: 0.4;
  }

  > svg path {
    fill: ${p => p.theme.color.blue200};
  }
`;

/* Link (Light) Variant */
const buttonVariantLinkLight = css`
  background-color: transparent;
  color: ${p => p.theme.color.white100};
  padding: 0;
  border: 0;
  font-size: 18px;
  font-weight: 500;

  &:hover:not(:disabled) {
    text-decoration: underline;
  }

  &:disabled {
    opacity: 0.4;
  }

  > svg path {
    fill: ${p => p.theme.color.white100};
  }
`;

/* Icon-sm Variant */
const buttonVariantIconSm = css`
  border: none;
  background: none;
  padding: 0;
  font-size: 18px;
  font-weight: 500;

  &:disabled {
    opacity: 0.4;
  }

  > :first-child {
    margin-right: 12px;
  }

  > img {
    width: 20px;
    height: auto;
  }

  > svg {
    width: 14px;
    height: auto;
  }
`;

/* Icon-lg Variant */
const buttonVariantIconLg = css`
  border: none;
  background: none;
  padding: 0;
  

  &:disabled {
    opacity: 0.4;
  }

  > img {
    width: 74px;
    height: auto;
  }

`;

/* Circle-clear Variant */
const buttonVariantCircleClear = css`
  border: none;
  border-radius: 50%;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  padding: 0;

  &:disabled {
    opacity: 0.4;
  }

  &:hover {
    background: ${p => p.theme.color.red200};
  }

  > span {
    color: ${p => p.theme.color.red500};
  }
`;

/* Circle-filled Variant */
const buttonVariantCircleFilled = css`
  border: none;
  border-radius: 50%;
  background: ${p => p.theme.color.red500};
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  padding: 0;

  &:disabled {
    opacity: 0.4;
  }

  &:hover {
    background: ${p => p.theme.color.red200};
  }

  > span {
    color: ${p => p.theme.color.white100};
  }
`;

export const Wrapper = styled.button`
  align-items: center;
  cursor: pointer;
  transition: all 300ms ease;
  padding: 4px 16px;

  &:disabled {
    cursor: not-allowed;
  }

  > span,
  > svg {
    display: block;
  }

  > svg {
    width: auto; /* Height is set on size styles */

    &:first-child:not(:last-child) {
      margin-right: 8px;
    }

    &:last-child:not(:first-child) {
      margin-left: 8px;
    }
  }

  /* Block or Inline */
  ${p => (p.block ? buttonDisplayBlock : buttonDisplayInline)}

  /* Alignment */
  ${p => p.align === 'left' && buttonAlignLeft}
  ${p => p.align === 'center' && buttonAlignCenter}
  ${p => p.align === 'right' && buttonAlignRight}

  /* Sizes */
  ${p => p.size === 'xs' && buttonSizeXs}
  ${p => p.size === 'sm' && buttonSizeSm}
  ${p => p.size === 'md' && buttonSizeMd}
  ${p => p.size === 'lg' && buttonSizeLg}

  /* Variants */
  ${p => p.variant === 'primary' && buttonVariantPrimary}
  ${p => p.variant === 'download' && buttonVariantDownload}
  ${p => p.variant === 'ghost' && buttonVariantGhost}
  ${p => p.variant === 'ghost-light' && buttonVariantGhostLight}
  ${p => p.variant === 'text' && buttonVariantText}
  ${p => p.variant === 'text-light' && buttonVariantTextLight}
  ${p => p.variant === 'link' && buttonVariantLink}
  ${p => p.variant === 'link-light' && buttonVariantLinkLight}
  ${p => p.variant === 'icon-sm' && buttonVariantIconSm}
  ${p => p.variant === 'icon-lg' && buttonVariantIconLg}
  ${p => p.variant === 'circle-clear' && buttonVariantCircleClear}
  ${p => p.variant === 'circle-filled' && buttonVariantCircleFilled}
`;
