import styled from 'styled-components';

export const Wrapper = styled.div`
  border-radius: 12px;
  padding: 8px;
  box-shadow: 0 0 0 2px rgba(0, 0, 0, 0.3), 0 4px 8px rgba(0, 0, 0, 0.2); /* Darker shadow for 3D effect */
  background-color: white;
  /* react-modal Transitions */
  .ReactModal__Content & {
    opacity: 0.85;
    transition: all 150ms ease-in-out;
  }

  .ReactModal__Content--after-open & {
    /* DEFAULT STYLES */
    opacity: 1;
    width: 33vw;

    /* PHONE STYLES */
    @media only screen and (max-width: 767px) {
      @media (orientation: portrait) {
        width: 85vw;
      }
      @media (orientation: landscape) {
        width: 90vh;
      }
    }

    /* TABLET STYLES */
    @media only screen and (min-width: 768px) and (max-width: 1369px) {
      @media (orientation: portrait) {
        width: 30vh;
      }
      @media (orientation: landscape) {
        width: 75vh;
      }
    }
  }

  .ReactModal__Content--before-close & {
    opacity: 0.85;
    /*transform: translateY(16px);*/
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-bottom: 12px;
`;
