export const lookupFilter = {
  'Beginning reader': 'beginning',
  'Early elementary': 'early',
  'Middle elementary': 'middle',
  'Late elementary': 'late',
  'Early middle school': 'earlyMiddle',
  'Late middle school': 'lateMiddle',
  'High school': 'high',
  French: 'french',
  Spanish: 'spanish',
  Wordless: 'wordless',
  'Haitian Creole': 'creole',
  English: 'english',
  Adventure: 'adventure',
  Fantasy: 'fantasy',
  Mystery: 'mystery',
  Cats: 'cats',
  Dogs: 'dogs',
  Insects: 'insects',
  Environment: 'environment',
  'Other Fiction': 'otherFiction',
  Animals: 'animals',
  Birds: 'birds',
  Fish: 'fish',
  Nature: 'nature',
  Water: 'water',
  Culture: 'culture',
  Family: 'family',
  Feelings: 'feelings',
  Food: 'food',
  Friends: 'friends',
  School: 'school',
  Sports: 'sports',
  Biography: 'biography',
  History: 'history',
  Math: 'math',
  Religion: 'religion',
  Science: 'science',
  'Other Non-fiction': 'otherNonfiction',
  Fiction: 'fiction',
  'Fiction books': 'fiction',
  'Non-fiction': 'nonFiction',
  'Non-fiction books': 'nonFiction',
  'Graphic novels': 'graphicNovels',
  'Book series': 'bookSeries',
  Storyweaver: 'storyweaver',
  'African Storybook': 'africanStorybook',
  'David Revoy': 'davidRevoy',
  Bookdash: 'bookdash',
  'Mustard Seed': 'mustardSeed',
  'Bible for Children': 'bibleForChildren',
  UNESCO: 'unesco',
  'Storybooks Canada': 'storybooksCanada',
  Bookbot: 'bookbot',
  'Book Sprint': 'bookSprint',
};
