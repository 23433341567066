import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100vw;
`;

export const TopWrapper = styled.header`
  position: relative;
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 0 auto;
`;

export const HeroWrapper = styled.header`
  position: relative;
  width: 100vw;
  height: auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: 0 auto;
`;

export const IllustrationWrapper = styled.img`
  position: absolute;
  width: calc(100% - 88px);
  margin-top: 150px;
  z-index: 0;
  pointer-events: none;
`;

export const BackgroundWrapper = styled.span`
  position: absolute;
  height: 100vh;
  width: 100vw;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  z-index: -2;
`;

export const FilterWrapper = styled.span`
  position: absolute;
  background-color: ${p => p.theme.color.blue100}da;
  height: 100vh;
  width: 100vw;
  z-index: -1;
`;

export const FiltersWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-grow: 0;

  @media ${p => p.theme.device.tablet} {
    width: 300px;
  }

  @media ${p => p.theme.device.desktop} {
    width: 380px;
  }
`;

export const TitleWrapper = styled.span`
  color: ${p => p.theme.color.blue500};
  text-align: center;
  margin: 60px 0px 16px;
  padding-top: 64px;
  width: 640px;
  max-width: 100%;
  @media ${p => p.theme.device.desktop} {
    margin-top: 160px;
    width: 820px;
  }
`;

export const ContentWrapper = styled.div`
  color: ${p => p.theme.color.black100};
  display: flex;
  flex-direction: column;
  text-align: center;
  font-family: sans-serif;
  width: 660px;
  max-width: 90%;
  @media ${p => p.theme.device.desktop} {
    width: 820px;
  }
`;

export const ButtonsWrapper = styled.div`
  margin: 20px 0;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  > :not(:last-child) {
    margin-right: 16px;
  }
`;

export const FoldWrapper = styled.section`
  position: relative;
  width: calc(100% - 88px);
  margin: 40px 44px;

  > :first-child {
    text-align: center;
    margin-bottom: 44px;
    color: ${p => p.theme.color.blue500};
  }

  @media ${p => p.theme.device.desktop} {
    margin: 80px 44px;
  }
`;

export const TilesWrapper = styled.section`
  width: 100%;
  max-width: 1760px;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: row;

  > :not(:last-child) {
    margin-right: 25px;
  }

  @media (max-width: 768px) { // Adjust the max-width value based on your needs
    flex-direction: column;
    align-items: stretch; // Ensure tiles stretch to fill the width
    width: 100%; // Full width for mobile screens
  }
`;

export const Tile = styled.div`
  min-height: 492px;
  width: calc(33.33% - 25px);
  color: ${p => p.theme.color.white100};
  background-color: ${p => p.theme.color[p.color] || p.theme.color.white100};
  border-radius: 20px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box; // Ensure padding is included in width calculation

  > h3 {
    font-size: 22px;
    color: ${p => p.theme.color.white100};
    text-align: center;
    margin-top: 32px;
  }

  > :first-child {
    margin: 0 auto;
  }

  @media ${p => p.theme.device.desktop} {
    min-height: 600px;
    padding: 50px;
  }

  @media (max-width: 768px) { // Adjust based on your mobile breakpoint
    width: 100%; // Full width on mobile
    min-height: auto; // Allow height to adjust based on content
    margin-bottom: 16px; // Add space between stacked tiles
    &:last-child {
      margin-bottom: 0; // Remove margin for the last tile
    }
  }
`;

export const Divider = styled.div`
  width: 100%;
  height: 0px;
  opacity: ${p => p.opacity || 1};
  border: 1px solid
    ${p => (p.color ? p.theme.color[p.color] : p.theme.color.white100)};
  margin: 24px auto;
`;

export const ChecksWrapper = styled.div`
  width: 100%;
  height: auto;
  max-height: 300px;
  display: flex;
  flex-direction: ${p => (p.columns ? 'row' : 'column')};
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
`;

export const RowWrapper = styled.span`
  width: 100%;
  display: flex;
  flex-direction: row;
  text-align: left;

  button {
    margin-right: 16px;
    margin: 0 auto;
  }

  p {
    text-transform: uppercase;
  }
`;

export const DonateWrapper = styled.section`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  font-family: sans-serif;
  width: calc(100% - 88px);
  margin: 0 auto;
  margin-top: ${p => p.marginTop || 0}px;

  > :not(:last-child) {
    margin-bottom: 32px;
  }

  h1 {
    color: ${p => p.theme.color.blue500};
  }
  p {
    color: ${p => p.theme.color.black200};
  }

  @media ${p => p.theme.device.desktop} {
    max-width: 940px;
  }
`;

export const TeamWrapper = styled.section`
  width: 100%;
  max-width: 1760px;
  margin: 0 auto;
  height: 560px;
  margin-top: 60px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;

  > * {
    margin: 0 auto;
  }

  > h1 {
    color: ${p => p.theme.color.blue500};
    margin-bottom: 40px;

    @media ${p => p.theme.device.desktop} {
      margin-bottom: 64px;
    }
  }

  > button {
    margin-top: 20px;
  }

  @media ${p => p.theme.device.desktop} {
    height: 640px;
    margin-top: 100px;
  }
`;

export const TeamColumns = styled.div`
  width: 96%;
  height: 55%;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;

  @media ${p => p.theme.device.desktop} {
    height: 75%;
    width: 90%;
  }
  > * {
    width: calc(33.3% - 60px);
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;

    > * {
      margin: 0 auto 10px;
      @media ${p => p.theme.device.desktop} {
        margin: 0 auto 20px;
      }
    }

    > img {
      width: 60%;
      margin-bottom: 40px;

      @media ${p => p.theme.device.desktop} {
        margin-bottom: 60px;
      }
    }

    > p {
      text-transform: uppercase;
    }
  }
`;

export const Team = styled.img`
  position: absolute;
  top: 110px;
  width: 100%;
  margin: 0;
  z-index: -1;
  pointer-events: none;

  @media ${p => p.theme.device.desktop} {
    top: 100px;
    width: calc(100% - 88px);
    margin: 0px 44px;
  }
`;

export const PitchWrapper = styled.div`
  display: flex;
  flex-direction: column;
  & > * {
    margin-bottom: 16px; /* Adjust the value as needed */
  }
`;